import {
  type ThemeOptions,
  type ComponentsProps,
  type ComponentsOverrides,
  type ComponentsVariants
} from '@mui/material/styles';

import { type Theme } from '../ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['FormAnnualInvestor'] = {};

const styleOverrides: ComponentsOverrides<Theme>['FormAnnualInvestor'] = {
  root: ({ theme, ownerState }) => ({
    containerType: 'inline-size',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  }),

  confirmationModal: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'navy' },
      theme
    }),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 0,
    padding: 'var(--section-padding) var(--grid-gap-double)',
    textAlign: 'center'
  }),

  contentOuterGrid: {
    'gridGap': 0,
    '& > *': {
      gridColumnStart: 'full-start',
      gridColumnEnd: 'full-end'
    }
  },

  successContentOuterGrid: ({ theme, ownerState }) => ({
    gridGap: 0,
    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'lightGray' },
      theme
    })
  }),

  formBottomWrap: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'white' },
      theme
    }),

    gridColumnStart: 'full-start',
    gridColumnEnd: 'end',
    gridRow: 1,

    [theme.breakpoints.up('md')]: {
      gridColumnEnd: 'four-end'
    }
  }),

  rsvpText: ({ theme, ownerState }) => ({
    '& *': {
      ...theme.palette.primary.contrastText
    },
    'gridColumnStart': 'start',
    'gridColumnEnd': 'end',
    'gridRow': 1,
    'padding': 'var(--section-padding) 0'
  }),

  agendaWrap: ({ theme, ownerState }) => ({
    gridColumnStart: 'full-start',
    gridColumnEnd: 'end',
    gridRow: 2,

    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'white' },
      theme
    }),

    [theme.breakpoints.up('md')]: {
      gridColumnEnd: 'half',
      gridRow: 2
    }
  }),

  formBottom: ({ theme, ownerState }) => ({
    padding: 'var(--section-padding) 0',
    position: 'sticky',
    top: 0
  }),

  timeline: ({ theme, ownerState }) => ({
    padding: 0
  }),

  timelineContent: ({ theme, ownerState }) => ({
    alignSelf: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    flex: 2
  }),

  timelineConnector: ({ theme, ownerState }) => ({
    minHeight: 'var(--grid-gap-double)',

    [theme.containerBreakpoints.up('md')]: {
      minHeight: 'var(--grid-gap)'
    }
  }),

  dayHeaderWrap: ({ theme, ownerState }) => ({
    '&': {
      padding: 'var(--grid-gap-half)',
      textAlign: 'center',
      position: 'sticky',
      top: 0,
      backgroundColor: 'inherit',
      zIndex: 100
    }
  }),

  sessionsWrap: ({ theme, ownerState }) => ({
    gridColumnStart: 'start',
    gridColumnEnd: 'end',
    gridRow: 2,

    [theme.breakpoints.up('md')]: {
      gridColumnStart: 'five-start',
      gridColumnEnd: 'full-end',
      gridRow: 1
    }
  }),

  submitButton: {
    zoom: 1.5,
    marginTop: 'var(--grid-gap-half)'
  },

  successItems: ({ theme, ownerState }) => ({
    'gridColumnStart': 'start',
    'gridColumnEnd': 'end',
    'gridRow': 3,

    [theme.containerBreakpoints.up('md')]: {
      gridColumnStart: 'half',
      gridColumnEnd: 'full-end',
      gridRow: 2
    },

    '& > *': {
      padding: 'var(--grid-gap-double) 0'
    }
  }),

  sessionsIntroWrap: ({ theme, ownerState }) => ({
    padding: 'var(--section-padding) 0 var(--grid-gap-double)',

    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'lightGray' },
      theme
    })
  }),

  sessionDay: ({ theme, ownerState }) => ({
    padding: 'var(--grid-gap-double) 0',

    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'lightGray' },
      theme
    })
  }),

  customCheckbox: ({ theme }) => ({
    '& .MuiSvgIcon-root': {
      'fontSize': 24, // Adjust the size of the icon as needed
      'position': 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '50%',
        background: 'gray', // Gray circle background
        opacity: 0.5, // Initial opacity
        zIndex: -1
      },
      '&.Mui-checked::before': {
        opacity: 1 // Opacity when checked
      }
    }
  }),

  formFields: ({ theme }) => ({
    'gridColumnStart': 'start',
    'gridColumnEnd': 'end',

    [theme.containerBreakpoints.up('md')]: {
      gridColumnStart: 'two-start',
      gridColumnEnd: 'ten-end'
    },

    ':is(input, label, select, .MuiSelect-standard)': {
      ...theme.typography.h5,
      'marginBottom': 0,

      'color': 'var(--mui-palette-text-primary) !important',

      '&:focus': {
        backgroundColor: 'initial'
      }
    },

    ':is( .MuiSelect-standard)': {
      marginTop: 'var(--grid-gap)'
    },

    '.MuiInput-root::before': {
      borderColor: 'var(--mui-palette-text-primary) !important',
      opacity: 0.5
    },

    '.MuiInput-root::after': {
      borderColor: 'var(--mui-palette-text-primary) !important',
      opacity: 0.5
    }
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['FormAnnualInvestor'] => [];

export const formAnnualInvestorTheme = (theme: Theme): ThemeOptions => ({
  components: {
    FormAnnualInvestor: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default formAnnualInvestorTheme;
