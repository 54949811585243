import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

import { type Theme } from '../ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['LinkIcs'] = {
  defaultVisibility: false,
  icon: 'logo',
  iconPosition: 'Left'
};

const styleOverrides: ComponentsOverrides<Theme>['LinkIcs'] = {
  root: {
    '& a': {
      whiteSpace: 'nowrap'
    }
  },

  calendarMenu: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'navy' },
      theme
    })
  }),

  calendarMenuItem: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'navy' },
      theme
    })
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['LinkIcs'] => [];

export const LinkIcsTheme = (theme: Theme): ThemeOptions => ({
  components: {
    LinkIcs: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default LinkIcsTheme;
