import {
  type ThemeOptions,
  type ComponentsProps,
  type ComponentsOverrides,
  type ComponentsVariants
} from '@mui/material/styles';

import { type Theme } from '../ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['FormGolf2024'] = {};

const styleOverrides: ComponentsOverrides<Theme>['FormGolf2024'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    containerType: 'inline-size',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }),

  detailsLabel: {
    '&': {
      paddingBottom: 'var(--grid-gap)'
    }
  },

  contentWrap: ({ theme }) => ({
    ...theme.mixins.applyColorScheme({
      ownerState: { backgroundColor: 'white' },
      theme
    }),

    gridColumnStart: 'start',
    gridColumnEnd: 'end'
  }),

  bodyHeader: {
    '&:not(:first-of-type)': {
      paddingTop: 'var(--grid-gap)',
      marginTop: 'var(--grid-gap)',
      borderTop: 'solid',
      borderTopWidth: '1px'
    }
  },

  successWrap: {
    gridColumnStart: 'full-start',
    gridColumnEnd: 'full-end'
  },

  successContentOuterGrid: ({ theme, ownerState }) => ({
    gridGap: 0,
    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'lightGray' },
      theme
    })
  }),

  formFields: ({ theme, ownerState }) => ({
    'paddingBottom': 'var(--grid-gap-double)',
    '& > *': {
      'gridColumnStart': 'full-start',
      'gridColumnEnd': 'full-end',

      '*': {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: 'unset',
        marginBottom: 0
      },

      'label': {
        opacity: 0.5,
        color: 'var(--mui-palette-text-primary) !important'
      },

      '.MuiInput-root::before': {
        borderColor: 'var(--mui-palette-text-primary) !important',
        opacity: 0.5
      },

      '.MuiInput-root::after': {
        borderColor: 'var(--mui-palette-text-primary) !important',
        opacity: 0.5
      },

      [theme.containerBreakpoints.up('md')]: {
        gridColumnStart: 'start',
        gridColumnEnd: 'end'
      },

      [theme.containerBreakpoints.up('lg')]: {
        gridColumnStart: 'two-start',
        gridColumnEnd: 'eleven-end',

        ...((ownerState?.formLayout === 'twoColumn' || ownerState?.formLayout === 'block') && {
          '&:nth-child(odd)': {
            gridColumnStart: 'start',
            gridColumnEnd: 'half'
          },

          '&:nth-child(even)': {
            gridColumnStart: 'half',
            gridColumnEnd: 'end'
          },

          '&[class*=fullWidth]': {
            gridColumnStart: 'start',
            gridColumnEnd: 'end'
          }
        })
      },

      [theme.containerBreakpoints.up('xl')]: {
        gridColumnStart: 'quarter',
        gridColumnEnd: 'three-quarter'
      }
    }
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['FormGolf2024'] => [];

export const formGolf2024Theme = (theme: Theme): ThemeOptions => ({
  components: {
    FormGolf2024: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default formGolf2024Theme;
