import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import type { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['CookieBot'] = {};

const styleOverrides: ComponentsOverrides<Theme>['CookieBot'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    padding: `var(--section-padding) 0 var(--section-padding)`
  }),

  contentOuterGrid: {},

  cookieWrapper: ({ theme }) => ({
    gridColumnStart: 'start',
    gridColumnEnd: 'half',
    gridRow: 1,
    alignSelf: 'center',
    width: '100%',
    height: 'auto',
    display: 'block',

    [theme.breakpoints.up('md')]: {
      gridColumnEnd: 'quarter'
    }
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['CookieBot'] => [];

export const cookiebotTheme = (theme: Theme): ThemeOptions => ({
  components: {
    CookieBot: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default cookiebotTheme;
