import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import type { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['PageEvent'] = {};

const styleOverrides: ComponentsOverrides<Theme>['PageEvent'] = {
  root: {
    '& > *': {
      paddingTop: 'var(--section-padding)',
      paddingBottom: 'var(--section-padding)'
    }
  }
};

const createVariants = (_theme: Theme): ComponentsVariants['PageEvent'] => [];

export const pageEventTheme = (theme: Theme): ThemeOptions => ({
  components: {
    PageEvent: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default pageEventTheme;
