import {
  type ThemeOptions,
  type ComponentsProps,
  type ComponentsOverrides,
  type ComponentsVariants
} from '@mui/material/styles';

import { type Theme } from '../ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['Agenda'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Agenda'] = {
  root: {
    containerType: 'inline-size',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },

  contentOuterGrid: {
    'gridGap': 0,
    '& > *': {
      gridColumnStart: 'full-start',
      gridColumnEnd: 'full-end'
    }
  },

  agendaWrap: ({ theme, ownerState }) => ({
    gridColumnStart: 'start',
    gridColumnEnd: 'end',
    gridRow: 2,

    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'white' },
      theme
    })
  }),

  timeline: {
    padding: 0
  },

  timelineContent: ({ theme }) => ({
    alignSelf: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    flex: 2,

    [theme.containerBreakpoints.up('md')]: {
      flex: 2
    }
  }),

  timelineConnector: ({ theme }) => ({
    minHeight: 'var(--grid-gap-double)',

    [theme.containerBreakpoints.up('md')]: {
      minHeight: 'var(--grid-gap)'
    }
  }),

  dayHeaderWrap: {
    '&': {
      padding: 'var(--grid-gap-half)',
      textAlign: 'center',
      position: 'sticky',
      top: 0,
      backgroundColor: 'inherit',
      zIndex: 100
    }
  },

  sessionsWrap: {
    gridColumnStart: 'start',
    gridColumnEnd: 'end',
    gridRow: 1
  },

  sessionsIntroWrap: ({ theme, ownerState }) => ({
    padding: 'var(--section-padding) 0 var(--grid-gap-double)',

    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'lightGray' },
      theme
    })
  }),

  sessionDay: ({ theme, ownerState }) => ({
    padding: 'var(--grid-gap-double) 0',

    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, backgroundColor: 'lightGray' },
      theme
    })
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['Agenda'] => [];

export const agendaTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Agenda: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default agendaTheme;
